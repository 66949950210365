<template>
  <v-navigation-drawer
    v-model="drawer"
    dark
    :expand-on-hover="expandOnHover"
    :right="$vuetify.rtl"
    mobile-breakpoint="960"
    app
    mini-variant-width="80"
    width="260"
    v-bind="$attrs"
  >
    <v-card
      height="80px"
      class="lighten-3 d-flex justify-center align-center mt-n2"
      color="rgba(0, 0, 0, 0.3)"
    >
      <div>
        <v-img
          src="@/assets/nrsLogo.png"
          alt="Logo"
          app
          class="ml-6"
          width="60"
        />
      </div>
      <v-spacer />
      <div>
        <h1
          class="mr-6 text-right"
          color="green white--text"
        >
          DMIS
        </h1>
      </div>
    </v-card>

    <v-divider class="mb-1" />

    <v-list
      dense
      nav
    >
      <base-item-group
        :item="profile"
      />
    </v-list>
    <v-divider class="mb-2" />
    <v-list
      expand
      nav
    >
      <div />
      <template
        v-for="(item, i) in computedItems"
      >
        <base-item-group
          v-if="item.children && item.show"
          :key="`group-${i}`"
          :item="item"
        >
          <!--  -->
        </base-item-group>
        <base-item
          v-else-if="item.show"
          :key="`item-${i}`"
          :item="item"
        />
      </template>
      <div />
    </v-list>
  </v-navigation-drawer>
</template>
<script>
// Utilities
import { mapState } from 'vuex'

export default {
  name: 'DashboardCoreDrawer',

  props: {
    expandOnHover: {
      type: Boolean,
      default: false
    }
  },

  data: () => ({
    privileges: null,
    items: [
      {
        icon: 'mdi-view-dashboard',
        title: 'dashboard',
        to: '/',
        show: true
      },
      {
        group: '/administration',
        icon: 'mdi-saw-blade',
        title: 'administration',
        show: '',
        children: [
          {
            title: 'centers',
            to: 'centers',
            show: ''
          },
          {
            title: 'roles',
            to: 'roles',
            show: ''
          },
          {
            title: 'positions',
            to: 'positions',
            show: ''
          },
          {
            title: 'users',
            to: 'users',
            show: ''
          }
          // {
          //   title: 'error',
          //   to: '404'
          // }
        ]
      },
      {
        icon: 'mdi-human-male-male',
        title: 'delinquents',
        group: '/delinquents',
        show: '',
        children: [
          {
            title: 'delinquents',
            to: 'delinquents',
            show: ''
          },
          {
            title: 'deviant-acts',
            to: 'deviant-acts',
            show: ''
          },
          {
            title: 'apprehension-items',
            to: 'apprehension-items',
            show: ''
          },
          {
            title: 'identification-doc',
            to: 'identification-doc',
            show: ''
          }
        ]
      },
      {
        icon: 'mdi-account-search',
        title: 'screening',
        group: '/screening',
        show: '',
        children: [
          {
            title: 'general-screening',
            to: 'general-screening',
            show: true
          },
          {
            title: 'medical-screening',
            to: 'medical-screening',
            show: true
          },
          {
            title: 'request-for-approval',
            to: 'request-for-approval',
            show: ''
          },
          {
            title: 'approval',
            to: 'approval',
            show: ''
          }
        ]
      },
      {
        icon: 'mdi-transit-transfer',
        title: 'movement',
        group: '',
        show: '',
        children: [
          {
            title: 'transfer',
            to: 'movement/transfer',
            show: ''
          },
          {
            title: 'receive',
            to: 'movement/receive',
            show: ''
          },
          {
            title: 'release',
            to: 'movement/release',
            show: ''
          },
          {
            title: 'Discharge',
            to: 'delinquents/discharge-delinquent',
            show: ''
          }
        ]
      },
      {
        icon: 'mdi-hospital-building',
        title: 'rehabilitation',
        group: '/rehabilitation',
        show: '',
        children: [
          {
            title: 'intake enrolment',
            to: 'intake-enrolment',
            show: ''
          },
          {
            title: 'behavior assessment',
            to: 'behaviorAssessment',
            show: ''
          },
          {
            title: 'psychotherapy',
            to: 'psychotherapy',
            show: ''
          },
          {
            title: 'trade enrolment',
            to: 'training-enrolment',
            show: ''
          }
        ]
      },
      {
        icon: 'mdi-school',
        title: 'graduation',
        group: '/graduation',
        show: '',
        children: [
          {
            title: 'manage-graduation',
            to: 'manage-graduation',
            show: ''
          },
          {
            title: 'prepare-list',
            to: 'prepare-list',
            show: ''
          },
          {
            title: 'request-graduation-approval',
            to: 'request-graduation-approval',
            show: ''
          },
          {
            title: 'approve-graduation-list',
            to: 'approve-graduation-list',
            show: ''
          },
          {
            title: 'release-graduates',
            to: 'release-graduates',
            show: ''
          }
        ]
      },
      {
        icon: 'mdi-school',
        title: 'post-graduation',
        group: '/post-graduation',
        show: '',
        children: [
          {
            title: 'organizations',
            to: 'organizations',
            show: ''
          },
          {
            title: 'graduates',
            to: 'graduates',
            show: ''
          }
        ]
      },
      {
        icon: 'mdi-file-find',
        title: 'Reports',
        group: '/reports',
        show: '',
        children: [
          {
            title: 'List of reports',
            to: 'list',
            show: true
          }
        ]
      }
    ]
  }),
  mounted () {
    this.privileges = this.currentUser.privileges
    this.checkPrivileges()
  },
  computed: {
    ...mapState('store', ['barColor', 'barImage', 'currentUser']),
    drawer: {
      get () {
        return this.$store.state.drawer
      },
      set (val) {
        this.$store.commit('store/SET_DRAWER', val)
      }
    },
    computedItems () {
      return this.items.map(this.mapItem)
    },
    profile () {
      if (this.currentUser) {
        return {
          avatar: false,
          group: '',
          // title: this.$t('avatar'),
          title: this.currentUser.lastName,
          children: [
            {
              to: 'userprofile',
              show: true,
              title: this.$t('edit-profile')
            },
            {
              to: 'changepassword',
              show: true,
              title: this.$t('change-password')
            }
          ]
        }
      } else {
        return {
          title: 'loading...'
        }
      }
    }
  },

  watch: {
    '$vuetify.breakpoint.smAndDown' (val) {
      this.$emit('update:expandOnHover', !val)
    }
  },

  methods: {
    mapItem (item) {
      return {
        ...item,
        children: item.children ? item.children.map(this.mapItem) : undefined,
        title: this.$t(item.title)
      }
    },
    checkPrivileges () {
      this.privileges.includes('READ_USERS') || this.privileges.includes('READ_ROLES') || this.privileges.includes('READ_CENTERS') || this.privileges.includes('READ_POSITIONS') || this.privileges.includes('ALL_AUTHORITIES') ? this.items[1].show = true : this.items[1].show = false // Admininistration Menu
      this.privileges.includes('READ_CENTERS') || this.privileges.includes('ALL_AUTHORITIES') ? this.items[1].children[0].show = true : this.items[1].children[0].show = false // Users sub-menu
      this.privileges.includes('READ_ROLES') || this.privileges.includes('ALL_AUTHORITIES') ? this.items[1].children[1].show = true : this.items[1].children[1].show = false // Roles sub-menu
      this.privileges.includes('READ_POSITIONS') || this.privileges.includes('ALL_AUTHORITIES') ? this.items[1].children[2].show = true : this.items[1].children[2].show = false // Centers sub-menu
      this.privileges.includes('READ_USERS') || this.privileges.includes('ALL_AUTHORITIES') ? this.items[1].children[3].show = true : this.items[1].children[3].show = false // Position uses user privileges

      this.privileges.includes('READ_DELINQUENTS') || this.privileges.includes('READ_DEVIANTACTS') || this.privileges.includes('READ_ITEMS') || this.privileges.includes('READ_DOCUMENTTYPES') || this.privileges.includes('ALL_AUTHORITIES') ? this.items[2].show = true : this.items[2].show = false // Manage Delinquent Menu
      this.privileges.includes('READ_DELINQUENTS') || this.privileges.includes('ALL_AUTHORITIES') ? this.items[2].children[0].show = true : this.items[2].children[0].show = false // Manage Delinquent sub-menu
      this.privileges.includes('READ_DEVIANTACTS') || this.privileges.includes('ALL_AUTHORITIES') ? this.items[2].children[1].show = true : this.items[2].children[1].show = false // Deviant Acts sub-menu
      this.privileges.includes('READ_ITEMS') || this.privileges.includes('ALL_AUTHORITIES') ? this.items[2].children[2].show = true : this.items[2].children[2].show = false // Apprehension Items sub-menu
      this.privileges.includes('READ_DOCUMENTTYPES') || this.privileges.includes('ALL_AUTHORITIES') ? this.items[2].children[3].show = true : this.items[2].children[3].show = false // Document Types sub-menu

      this.privileges.includes('READ_DELINQUENTS') || this.privileges.includes('ALL_AUTHORITIES') ? this.items[3].show = true : this.items[3].show = false // Screening menu

      this.privileges.includes('CREATE_GENERALSCREENINGS') || this.privileges.includes('UPDATE_GENERALSCREENINGS') || this.privileges.includes('DELETE_GENERALSCREENINGS') || this.privileges.includes('CREATE_MEDICALSCREENINGS') || this.privileges.includes('UPDATE_MEDICALSCREENINGS') || this.privileges.includes('DELETE_MEDICALSCREENINGS') || this.privileges.includes('APPROVE_SCREENINGS') || this.privileges.includes('ALL_AUTHORITIES') ? this.items[3].show = true : this.items[3].show = false // Screening Menu
      this.privileges.includes('CREATE_GENERALSCREENINGS') || this.privileges.includes('UPDATE_GENERALSCREENINGS') || this.privileges.includes('DELETE_GENERALSCREENINGS') || this.privileges.includes('ALL_AUTHORITIES') ? this.items[3].children[0].show = true : this.items[3].children[0].show = false // General Screening submenu
      this.privileges.includes('CREATE_MEDICALSCREENINGS') || this.privileges.includes('UPDATE_MEDICALSCREENINGS') || this.privileges.includes('DELETE_MEDICALSCREENINGS') || this.privileges.includes('ALL_AUTHORITIES') ? this.items[3].children[1].show = true : this.items[3].children[1].show = false // Medical Screening sub-menu
      this.privileges.includes('UPDATE_GENERALSCREENINGS') || this.privileges.includes('ALL_AUTHORITIES') ? this.items[3].children[2].show = true : this.items[3].children[2].show = false // Request for Approval sub-menu
      this.privileges.includes('APPROVE_SCREENINGS') || this.privileges.includes('ALL_AUTHORITIES') ? this.items[3].children[3].show = true : this.items[3].children[3].show = false // Approval sub-menu

      this.privileges.includes('UPDATE_RECEIVEINFO') || this.privileges.includes('UPDATE_DISCHARGEINFO') || this.privileges.includes('UPDATE_RELEASEINFO') || this.privileges.includes('UPDATE_TRANSFERS') || this.privileges.includes('UPDATE_TRANSFERS') || this.privileges.includes('UPDATE_RELEASEINFO') || this.privileges.includes('UPDATE_DISCHARGEINFO') || this.privileges.includes('ALL_AUTHORITIES') ? this.items[4].show = true : this.items[4].show = false // Delinquent Movement menu
      this.privileges.includes('UPDATE_TRANSFERS') || this.privileges.includes('ALL_AUTHORITIES') ? this.items[4].children[0].show = true : this.items[4].children[0].show = false // Transfer sub-menu
      this.privileges.includes('CREATE_TRANSFERS') || this.privileges.includes('UPDATE_RECEIVEINFO') || this.privileges.includes('ALL_AUTHORITIES') ? this.items[4].children[1].show = true : this.items[4].children[1].show = false // Receive sub-menu
      this.privileges.includes('UPDATE_RELEASEINFO') || this.privileges.includes('ALL_AUTHORITIES') ? this.items[4].children[2].show = true : this.items[4].children[2].show = false // Release sub-menu
      this.privileges.includes('UPDATE_DISCHARGEINFO') || this.privileges.includes('ALL_AUTHORITIES') ? this.items[4].children[3].show = true : this.items[4].children[3].show = false // Discharge menu

      this.privileges.includes('CREATE_INTAKE-ENROLMENTS') || this.privileges.includes('READ_ASSESSMENTS') || this.privileges.includes('READ_PSYCHOTHERAPYPROGRESSNOTES') || this.privileges.includes('READ_TRAININGCLASSES') || this.privileges.includes('READ_BEHAVIORASSESSMENTS') || this.privileges.includes('ALL_AUTHORITIES') ? this.items[5].show = true : this.items[5].show = false // Rehabilitation menu
      this.privileges.includes('CREATE_INTAKE-ENROLMENTS') || this.privileges.includes('ALL_AUTHORITIES') ? this.items[5].children[0].show = true : this.items[5].children[0].show = false // Intake Enrolment sub-menu
      this.privileges.includes('READ_BEHAVIORASSESSMENTS') || this.privileges.includes('ALL_AUTHORITIES') ? this.items[5].children[1].show = true : this.items[5].children[1].show = false // Behavior sub-menu
      this.privileges.includes('READ_PSYCHOTHERAPYPROGRESSNOTES') || this.privileges.includes('ALL_AUTHORITIES') ? this.items[5].children[2].show = true : this.items[5].children[2].show = false // Psychotherapy
      this.privileges.includes('READ_TRAININGCLASSES') || this.privileges.includes('ALL_AUTHORITIES') ? this.items[5].children[3].show = true : this.items[5].children[3].show = false // Training Enrolment sub-menu
      // this.privileges.includes('READ_ASSESSMENTS') || this.privileges.includes('ALL_AUTHORITIES') ? this.items[5].children[4].show = true : this.items[5].children[4].show = false //  Assessment sub-menu

      this.privileges.includes('READ_GRADUATIONS') || this.privileges.includes('UPDATE_GRADUATES-RELEASE-INFO') || this.privileges.includes('UPDATE_GRADUATION-APPROVAL-INFO') || this.privileges.includes('CREATE_GRADUATION-APPROVAL-REQUEST') || this.privileges.includes('UPDATE_GRADUANDS-LIST') || this.privileges.includes('ALL_AUTHORITIES') ? this.items[6].show = true : this.items[6].show = false // Graduation menu
      this.privileges.includes('READ_GRADUATIONS') || this.privileges.includes('ALL_AUTHORITIES') ? this.items[6].children[0].show = true : this.items[6].children[0].show = false // Manage graduation sub-menu
      this.privileges.includes('UPDATE_GRADUANDS-LIST') || this.privileges.includes('ALL_AUTHORITIES') ? this.items[6].children[1].show = true : this.items[6].children[1].show = false
      // if ((this.privileges.includes('CREATE_GRADUATIONS') && this.privileges.includes('READ_TRANSFERS')) || this.privileges.includes('ALL_AUTHORITIES')) {
      //   this.items[6].children[1].show = true
      // } else this.items[6].children[1].show = false // Prepare List
      this.privileges.includes('CREATE_GRADUATION-APPROVAL-REQUEST') || this.privileges.includes('ALL_AUTHORITIES') ? this.items[6].children[2].show = true : this.items[6].children[2].show = false // Request Approval
      this.privileges.includes('APPROVE_GRADUATIONS') || this.privileges.includes('ALL_AUTHORITIES') ? this.items[6].children[3].show = true : this.items[6].children[3].show = false // Approve Graduation List
      this.privileges.includes('UPDATE_GRADUATES-RELEASE-INFO') || this.privileges.includes('ALL_AUTHORITIES') ? this.items[6].children[4].show = true : this.items[6].children[4].show = false // Release Graduates

      this.privileges.includes('READ_ORGANIZATIONS') || this.privileges.includes('READ_GRADUATES') || this.privileges.includes('ALL_AUTHORITIES') ? this.items[7].show = true : this.items[7].show = false // Post Graduation menu
      this.privileges.includes('READ_ORGANIZATIONS') || this.privileges.includes('ALL_AUTHORITIES') ? this.items[7].children[0].show = true : this.items[7].children[0].show = false // Organizations sub-menu
      this.privileges.includes('READ_GRADUATES') || this.privileges.includes('ALL_AUTHORITIES') ? this.items[7].children[1].show = true : this.items[7].children[1].show = false // Graduates sub-menu

      // this.privileges.includes('READ_TRANSFERS') || this.privileges.includes('ALL_AUTHORITIES') ? this.items[8].show = true : this.items[8].show = false // Psycho Social menu
      // this.privileges.includes('READ_GRADUATIONS') || this.privileges.includes('ALL_AUTHORITIES') ? this.items[8].children[0].show = true : this.items[8].children[0].show = false // Behavior Assessment
      // this.privileges.includes('READ_GRADUATIONS') || this.privileges.includes('ALL_AUTHORITIES') ? this.items[8].children[1].show = true : this.items[8].children[1].show = false // Psychotherapy

      this.privileges.includes('READ_DELINQUENTS-REPORT') || this.privileges.includes('READ_GENERAL-SCREENING-REPORT') || this.privileges.includes('READ_MEDICAL-SCREENING-REPORT') || this.privileges.includes('READ_GRADUATION-REPORT') || this.privileges.includes('READ_TRADE-REPORT') || this.privileges.includes('READ_TRANSFERRED-DELINQUENT-REPORT') || this.privileges.includes('READ_POST-GRADUATION-FOLLOWUPREPORT') || this.privileges.includes('ALL_AUTHORITIES') ? this.items[8].show = true : this.items[8].show = false // Report menu
      // this.privileges.includes('READ_GRADUATIONS') || this.privileges.includes('ALL_AUTHORITIES') ? this.items[8].children[0].show = true : this.items[8].children[0].show = false // Report
    }
  }
}
</script>

<style lang="sass">
  @import '~vuetify/src/styles/tools/_rtl.sass'

  #core-navigation-drawer
    &.v-navigation-drawer--mini-variant
      .v-list-item
        justify-content: flex-start !important

      .v-list-group--sub-group
        display: block !important

    .v-list-group__header.v-list-item--active:before
      opacity: .24

    .v-list-item
      &__icon--text,
      &__icon:first-child
        justify-content: center
        text-align: center
        width: 20px

        +ltr()
          margin-right: 24px
          margin-left: 12px !important

        +rtl()
          margin-left: 24px
          margin-right: 12px !important

    .v-list--dense
      .v-list-item
        &__icon--text,
        &__icon:first-child
          margin-top: 10px

    .v-list-group--sub-group
      .v-list-item
        +ltr()
          padding-left: 8px

        +rtl()
          padding-right: 8px

      .v-list-group__header
        +ltr()
          padding-right: 0

        +rtl()
          padding-right: 0

        .v-list-item__icon--text
          margin-top: 19px
          order: 0

        .v-list-group__header__prepend-icon
          order: 2

          +ltr()
            margin-right: 8px

          +rtl()
            margin-left: 8px
</style>
